import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchJustificationproposals(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/justification-proposals', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchJustificationproposal(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/justification-proposals/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    approveJustificationproposal(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/justification-proposals/approve/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    rejectJustificationproposal(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/justification-proposals/reject/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addJustificationproposal(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/justification-proposals', formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteJustificationproposal(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/justification-proposals/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
