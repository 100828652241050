<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form ref="form">
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="CompassIcon"
            size="25"
            class="mr-2"
          />
          <h1>Nuova Proposta Ferie</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-2"
        >
          <b-form-group
            label="Data di Inizio *"
            label-for="start_day"
          >
            <b-form-datepicker
              id="start_day"
              v-model="justificationproposalData.start_day"
              placeholder="Scegli Data"
              locale="it"
              :min="minDay"
              today-button
              reset-button
              close-button
              label-today-button="Oggi"
              label-reset-button="Annulla"
              label-close-button="Chiudi"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              label-help="Scegli la data di Inizio"
              label-no-date-selected="Nessuna data selezionata"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-2"
        >
          <b-form-group
            label="Data di Fine *"
            label-for="end_day"
          >
            <b-form-datepicker
              id="end_day"
              v-model="justificationproposalData.end_day"
              placeholder="Scegli Data"
              locale="it"
              :date-disabled-fn="dateDisabledNew"
              today-button
              reset-button
              close-button
              label-today-button="Oggi"
              label-reset-button="Annulla"
              label-close-button="Chiudi"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              label-help="Scegli la data di Fine"
              label-no-date-selected="Nessuna data selezionata"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-2"
        >
          <b-form-group
            label="Giorni Massimi *"
            label-for="max_days"
          >
            <b-input-group prepend="Giorni">
              <b-form-input
                id="max_days"
                v-model="justificationproposalData.max_days"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Destinatario *"
            label-for="user_id"
          >
            <v-select
              id="user_id"
              :filterable="false"
              :options="usersPaginated"
              @open="onOpen"
              @close="onClose"
              @search="(query) => (search = query)"
              @input="selectUser($event)"
            >
              <template #list-footer>
                <li
                  v-show="hasNextPage"
                  ref="load"
                  class="loader"
                >
                  Carica più Utenti...
                </li>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <b-form-group
            label="Note"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="justificationproposalData.note"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="addJustificationproposal"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
            />
            <span v-if="!loading">Aggiungi</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BLink,
  BSpinner,
  BFormDatepicker,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import userStoreModule from '@/views/apps/user/userStoreModule'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import vSelect from 'vue-select'
import justificationproposalsStoreModule from '../justificationproposalsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BLink,
    BSpinner,
    BFormDatepicker,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BFormTextarea,
    vSelect,
  },
  setup() {
    const toast = useToast()

    const JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME = 'app-justificationproposals'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME)) {
      store.registerModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME, justificationproposalsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME)) { store.unregisterModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME) }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000, for_approvation: 1 })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    const minDay = moment().format('YYYY-MM-DD')

    return {
      minDay,
      users,
      loading: false,
      justificationproposalData: {
        start_day: null,
        end_day: null,
        user_id: null,
        max_days: null,
        note: null,
      },
      observer: null,
      limit: 10,
      search: '',
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    dateDisabledNew(ymd) {
      if (ymd <= this.justificationproposalData.start_day) return true
      return false
    },
    selectUser(e) {
      this.justificationproposalData.user_id = e.value
    },
    goBack() {
      this.$router.go(-1)
    },
    addJustificationproposal() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      this.justificationproposalData.max_days = parseInt(this.justificationproposalData.max_days)
      store
        .dispatch('app-justificationproposals/addJustificationproposal', this.justificationproposalData)
        .then(result => {
          this.$router.replace({ name: 'apps-managerjustificationproposals-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Proposta Ferie #${result.data.id} aggiunta con successo`,
                icon: 'CompassIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.justificationproposalData.start_day || this.justificationproposalData.start_day == '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Data',
            icon: 'AlertTriangleIcon',
            text: 'Inserire Data di Inizio della Proposta Ferie per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.justificationproposalData.end_day || this.justificationproposalData.end_day == '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Data',
            icon: 'AlertTriangleIcon',
            text: 'Inserire Data di Fine della Proposta Ferie per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.justificationproposalData.max_days) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Giorni',
            icon: 'AlertTriangleIcon',
            text: 'Inserire Giorni Massimi della Proposta Ferie per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.justificationproposalData.user_id) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Destinatario',
            icon: 'AlertTriangleIcon',
            text: 'Seleziona Utente destinatario della Proposta Ferie per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    reset() {
      this.$refs.form.reset()
      this.justificationproposalData = {
        start_day: '',
        end_day: '',
        user_id: null,
        max_days: null,
        note: null,
      }
    },
  },
}
</script>

<style scoped>
.position-absolute {
  z-index: 1;
}
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
